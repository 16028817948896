import {controller, attr} from '@github/catalyst'
import {softNavigate} from '@github-ui/soft-navigate'

@controller
class WorkflowRunBranchFilterElement extends HTMLElement {
  @attr branch = ''
  @attr url = ''

  filterBranch(event: Event) {
    const url = new URL(this.url, window.location.origin)
    const params = new URLSearchParams(url.search)

    const btn = event.target as HTMLElement
    const isCurrentlyFiltered = btn.getAttribute('aria-checked') === 'true'

    if (!isCurrentlyFiltered) {
      const filterQuery = params.get('query')
      const branchQuery = `branch:${this.branch}`
      params.set('query', filterQuery ? `${filterQuery} ${branchQuery}` : branchQuery)
    }

    url.search = params.toString()
    softNavigate(url.toString())
  }
}
