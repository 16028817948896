import {requestSubmit} from '@github-ui/form-utils'
import {controller, target} from '@github/catalyst'
import '@oddbird/popover-polyfill'

@controller
class ActionsStatusBadgeFormElement extends HTMLElement {
  @target form: HTMLFormElement

  #abortController: AbortController

  connectedCallback() {
    const {signal} = (this.#abortController = new AbortController())
    this.addEventListener('itemActivated', this, {signal})
  }

  disconnectedCallback() {
    this.#abortController.abort()
  }

  handleEvent() {
    if (!this.form) return

    requestSubmit(this.form)
  }
}

if (!window.customElements.get('actions-status-badge-form')) {
  window.customElements.define('actions-status-badge-form', ActionsStatusBadgeFormElement)
}
